<template>
  <div class="w-600">
    <v-card
      flat
      color="app_bg"
    >
      <div class="mt-10 text-center">
        <a href="home">
          <img
            width="130"
            src="../assets/logo.svg"
          >
        </a>
      </div>

      <v-card-text>
        <v-sheet
          rounded="lg"
          color="nav_bg"
          class="pa-10 my-5 text-center"
        >
          <v-icon
            x-large
            class="mb-3"
          >
            mdi-timer-sand
          </v-icon>
          <div class="text-h6">
            {{ $t('reg_wait01') }}
          </div>
          <v-divider class="my-5 opacity-5" />
          <div class="my-3 opacity-5">
            {{ $t('reg_wait02') }}
          </div>
          <div>
            <v-btn
              dark
              depressed
              rounded
              color="#0088cc"
              class="mx-1 text-capitalize"
              href="https://t.me/YeloBET"
              target="_blank"
            >
              {{ $t('kefu_tg') }}
            </v-btn>
            <v-btn
              depressed
              rounded
              color="#25d366"
              class="mx-1 text-capitalize"
              href="https://wa.me/447599034922"
              target="_blank"
            >
              {{ $t('kefu_ws') }}
            </v-btn>
          </div>
        </v-sheet>

        <v-btn
          outlined
          large
          block
          rounded
          color="primary"
          class="my-6"
          to="login"
        >
          {{ $t('logout') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    components:{
      
    },
    props: {

    },
    data () {
      return {
        username: '',
        password: '',
        show2: false,
      }
    },
    created() {
      if(this.$route.query.s){
        this.loginbys()
      }
    },
    methods: {
      loginbys() {
        let paramObj = {
          s: this.$route.query.s
        }
        this.$server.loginbys(paramObj).then((response) => {
          if(response.code==200){
            this.setStorage('agent_id', response.data.agent_id)
            this.setStorage('token', response.data.token)
            this.$router.push({
              name: 'Home',
            })
          }else{
            this.$snackbar.warning(response.msg)
          }
        })
      },
      login() {
        let paramObj = {
          username: this.username,
          password: this.password
        }
        this.$server.login(paramObj).then((response) => {
          if(response.code==200){
            this.setStorage('agent_id', response.data.agent_id)
            this.setStorage('token', response.data.token)
            this.$router.push({
              name: 'Home',
            })
          }else{
            this.$snackbar.warning(response.msg)
          }
        })
      },
    }
  }
</script>